import React, { useEffect } from "react";
import Footer from "components/Footers/Footer";
import IndexNavbar2 from "components/Navbars/IndexNavbar2";

const Aboutus = () => {

  //conent coming from left to right start
  useEffect(() => {
    const leftElements = document.querySelectorAll(".left-content");
    const rightElements = document.querySelectorAll(".right-content");

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add(
              entry.target.classList.contains("left-content")
                ? "slide-in-left"
                : "slide-in-right"
            );
            entry.target.classList.remove("opacity-0");
            observer.unobserve(entry.target);
          }
        });
      },
      { threshold: 0.1 }
    );

    leftElements.forEach((el) => {
      observer.observe(el);
    });
    rightElements.forEach((el) => {
      observer.observe(el);
    });

    return () => {
      leftElements.forEach((el) => observer.unobserve(el));
      rightElements.forEach((el) => observer.unobserve(el));
    };
  }, []);
  //conent coming from left to right end

  return (
    <section className="overflow-hidden">
      <IndexNavbar2 />
      <section
        style={{
          // backgroundImage: `url(${architecture})`,
          backgroundColor: "#000000",
          backgroundSize: "cover",
        }}
        className="xl:h-990 lg:h-750 md:h-600 h-400"
      >
        <div className="container xl:px-20 px-4 xl:pt-52 lg:pt-36 md:pt-32 pt-28 mx-auto flex flex-wrap ">
          <div className="grid md:grid-cols-2 grid-cols-1">
            <div className="right-content opacity-0">
              <p className="text-sm banner-font text-greyy font-medium text-left">
                ABOUT US
              </p>
              <h1 className="pt-4 text-white xl:text-5xl lg:text-3xl md:text-2xl text-2xl banner-font">
                We shape our building shomes gardens thereafter they shape us
              </h1>
            </div>
          </div>
        </div>
        <div className="left-content opacity-0 container xl:px-20 lg:px-12 md:px-12 px-4 xl:pt-12 lg:pt-12 md:pt-12 pt-8 mx-auto">
          <img
            src="https://i.ibb.co/G2H51ZV/d5-render-KSlj-Cph-BZ6-Q-unsplash.jpg"
            className="w-full"
          />
        </div>
      </section>

      <section
        style={{
          // backgroundImage: `url(${architecture})`,
          backgroundColor: "#bebebf",
          backgroundSize: "cover",
        }}
        className=""
      >
        <div className="container mx-auto overflow-hidden px-4 xl:py-16 xl:px-12 lg:py-8 py-6">
          <div className="grid md:grid-cols-2 gap-4 xl:pt-20 lg:pt-20 xl:px-20 md:pt-12 pt-12">
            <h1 className="left-content opacity-0 text-white xl:text-4xl lg:text-4xl md:text-2xl text-2xl banner-font">
              Architecture is the will of an epoch translated into space.
            </h1>
            <p className="right-content opacity-0 xl:text-base1 lg:text-lg md:text-sm banner-font text-white font-medium text-left">
              Exercitation photo booth stumptown tote bag Banksy, elit small
              batch freegan sed. Craft beer elit seitan exercitation, photo
              booth et 8-bit kale chips proident chillwave deep v laborum.
              Aliquip veniam delectus, Marfa eiusmod Pinterest in do umami
              readymade swag. Selfies iPhone Kickstarter, drinking vinegar jean
              vinegar stumptown yr pop-up artisan.
            </p>
          </div>
        </div>
      </section>

      <section className="bg-white">
        <div className="container mx-auto overflow-hidden px-4 xl:py-16 xl:px-12 lg:py-8 py-6">
          <div className="md:flex flex-none xl:w-12/12 gap-12 xl:px-8 mt-8">
            <div className="left-content opacity-0 xl:w-8/12 lg:w-8/12 md:w-8/12 w-12/12 xl:mt-24 lg:mt-24 md:pt-8">
              <p className="lg:text-3xl xl:text-4xl md:text-2xl text-2xl banner-font text-black font-medium text-left">
                Good design is obvious. Great design is transparent. ———
              </p>
              <p className="xl:pt-8 pt-4 text-left xl:text-base1 text-base banner-font">
                Meh synth Schlitz, tempor duis single-origin coffee ea next
                level ethnic fingerstache fanny pack nostrud. Photo booth anim
                8-bit hella, PBR 3 wolf moon beard Helvetica. Salvia esse nihil,
                flexitarian Truffaut synth art party deep v chillwave. Seitan
                High Life reprehenderit consectetur cupidatat kogi. Et leggings
                fanny pack.
              </p>
              <p className="xl:text-base1 xl:pt-8 pt-4 cursor-pointer banner-font hoverminus text-greyy font-medium text-left">
                Learn More ⟶
              </p>
            </div>
            <div className="right-content opacity-0 xl:w-4/12 lg:w-4/12 md:w-4/12 w-12/12 md:mt-0 mt-4">
              <img
                src="https://i.ibb.co/g322j70/arno-senoner-WFzf-X-n7qv4-unsplash-768x1151.jpg"
                className="w-full"
              />
            </div>
          </div>
        </div>
      </section>

      <section className="bg-white">
        <div className="container mx-auto overflow-hidden px-4 xl:pb-16 xl:px-12 lg:pb-8 pb-6">
          <div className="md:flex flex-none xl:w-12/12 gap-12 xl:px-8 mt-8">
            <div className="left-content opacity-0 md:block hidden xl:w-4/12 lg:w-4/12 md:w-4/12 w-12/12">
              <img
                src="https://i.ibb.co/CJXXKvG/r-architecture-Kq-Ma-A8g-Dfl0-unsplash-1-768x1152.jpg"
                className="w-full"
              />
            </div>
            <div className="right-content opacity-0 xl:w-8/12 lg:w-8/12 md:w-8/12 w-8/12 xl:mt-24 lg:mt-24 md:mt-8">
              <p className="lg:text-3xl xl:text-4xl md:text-2xl text-2xl banner-font text-black font-medium text-left">
                Design is where science and art break even. ———
              </p>
              <p className="xl:pt-8 pt-4 text-left xl:text-base1 text-base banner-font">
                Meh synth Schlitz, tempor duis single-origin coffee ea next
                level ethnic fingerstache fanny pack nostrud. Photo booth anim
                8-bit hella, PBR 3 wolf moon beard Helvetica. Salvia esse nihil,
                flexitarian Truffaut synth art party deep v chillwave. Seitan
                High Life reprehenderit consectetur cupidatat kogi. Et leggings
                fanny pack.
              </p>
              <p className="xl:text-base1 xl:pt-8 pt-4 cursor-pointer banner-font hoverminus text-greyy font-medium text-left">
                Learn More ⟶
              </p>
            </div>
            <div className="left-content opacity-0 md:hidden block xl:w-4/12 lg:w-4/12 md:w-4/12 w-12/12 md:mt-0 mt-4">
              <img
                src="https://i.ibb.co/CJXXKvG/r-architecture-Kq-Ma-A8g-Dfl0-unsplash-1-768x1152.jpg"
                className="w-full"
              />
            </div>
          </div>
        </div>
      </section>

      <section
        style={{
          backgroundColor: "#f5f5f5",
          backgroundSize: "cover",
        }}
        className=""
      >
        <div className="container mx-auto overflow-hidden px-4 xl:py-16 xl:px-12 lg:py-16 py-6 md:py-12">
          <div className="grid md:grid-cols-2 grid-cols-1 xl:px-40 lg:px-32 md:px-20 px-4 items-center">
            <div className="left-content opacity-0 md:border-l border-none pl-8 z-2">
              <p className="lg:text-4xl xl:text-5xl md:text-2xl text-3xl banner-font text-black font-medium text-left">
                A designer is a planner with an aesthetic sense.
              </p>
              <img
                src="https://i.ibb.co/JxLsr5D/signature.png"
                className="mt-8 xl:w-4/12 lg:w-4/12 md:w-4/12 w-4/12"
              />
              <p className="text-sm pt-6 banner-font text-greyy font-medium text-left">
                VICTOR KOLEMKO – CEO
              </p>
            </div>
            <div className="right-content opacity-0 xl:-ml-28 lg:-ml-20 md:-ml-16 md:mt-0 mt-4">
              <img
                src="https://i.ibb.co/bWWQvch/wiktor-karkocha-WA2u-SCb-TXk-I-unsplash-1536x1024.jpg"
                className="w-full"
              />
            </div>
          </div>
        </div>
      </section>

      <Footer fixed />
    </section>
  );
};

export default Aboutus;

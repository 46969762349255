import React from "react";
import EClogo from "assets/img/EClogo.png";
import EClogowhite from "assets/img/EClogowhite.png";

export default function Footer() {
  return (
    <>
      <footer className="relative fixed bottom-0">
        {/* <section className="bg-white md:block hidden" >
          <div className="container w-full mx-auto lg:w-12/12 md:w-12/12 md:pt-6 md:pb-6">
            <div className="flex flex-wrap">
              <div className="lg:w-4/12 md:w-4/12 text-center justify-center ">
                <a href="https://www.facebook.com/elegantcarve2022" target="_blank">
                  <i className="text-blue fab fa-facebook text-2xl leading-lg px-4" />
                </a>
                <a href=" https://twitter.com/elegantcarve?lang=en" target="_blank">
                  <i className="text-blue fab fa-twitter text-2xl leading-lg px-4" />
                </a>
                <a href=" https://instagram.com/elegantcarvetechnologies?igshid=YmMyMTA2M2Y=" target="_blank">
                  <i className="text-blue fab fa-instagram-square text-2xl leading-lg px-4" />
                </a>
                <a href=" https://in.linkedin.com/company/elegantcarve" target="_blank">
                  <i className="text-blue fab fa-linkedin text-2xl leading-lg px-4" />
                </a>
                <a href=" https://in.pinterest.com/elegantcarve/" target="_blank">
                  <i className="text-blue fab fa-pinterest text-2xl leading-lg px-4" />
                </a>
              </div>
              <div className="lg:w-1/12 md:w-1/12">
              </div>
              <div className="lg:w-7/12 md:w-7/12 center">
                <h1 className="head1 text-blue text-base pt-2 pl-2">
                  Sitemap
                  <span className="px-4">Terms</span>
                  <span className="px-2">Privacy Notice</span>
                  <span className="px-2">Cookie Notice</span>
                </h1>
              </div>
            </div>
          </div>
        </section> */}

        <section
          style={{ backgroundColor: "#262724" }}
          className="xl:pb-20 lg:pb-8 md:pb-8 md:block hidden"
        >
          <div className="container mx-auto overflow-hidden pt-12 xl:px-20 lg:px-4 md:px-4">
            <div className="w-full flex lg:w-12/12 md:w-12/12">
              <div className="lg:w-5/12 md:w-5/12">
                <p className="head1 xl:text-4xl text-greyy pt-1">
                  archicon@qode
                </p>
                <p className="head1 xl:text-base1 text-greyy pt-4 xl:mr-8">
                  Showcase projects with Archicon especially made for
                  architecture & interior design.
                </p>
                <div className="flex gap-8 xl:pt-19 lg:pt-8 md:pt-4">
                  <p className="head1 text-smm text-greyy cursor-pointer">
                    Services
                  </p>
                  <p className="head1 text-smm text-greyy cursor-pointer">
                    About Us
                  </p>
                  <p className="head1 text-smm text-greyy cursor-pointer">
                    Contact Us
                  </p>
                </div>
              </div>
              <div className="flex justify-between lg:w-4/12 md:w-4/12 xl:ml-8">
                <div></div>
                <div className="border-l pl-8">
                  <p className="head1 text-sm text-greyy pt-1">
                    46 Skabersjogatan,
                  </p>
                  <p className="head1 text-smm text-greyy pt-3">
                    Malmo, Sweden
                  </p>
                  <p className="head1 text-smm text-greyy pt-3">
                    +347 456 1379
                  </p>
                  <p className="head1 text-smm text-greyy pt-3">
                    archicon@example.com
                  </p>
                  <p className="head1 text-smm text-greyy xl:pt-12">
                    © 2022 Qode Interactive
                  </p>
                </div>
              </div>
              <div className="flex justify-between lg:w-3/12 md:w-3/12">
                <div></div>
                <div className="border-l pl-8">
                  <p className="head1 text-smm text-greyy pt-1">Instagram</p>
                  <p className="head1 text-smm text-greyy pt-3">LinkedIn</p>
                  <p className="head1 text-smm text-greyy pt-3">YouTube</p>
                  <p className="head1 text-smm text-greyy pt-3">Behance</p>
                  <p className="head1 text-smm text-greyy  xl:pt-12">
                    Join our newsletter
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Mobile View */}
        <section style={{ backgroundColor: "#262724" }} className=" md:hidden block">
          <div className="container w-full mx-auto lg:w-12/12 md:w-12/12 pt-6 pb-6">
            <div className="flex flex-wrap center-items">
              <div className="lg:w-4/12 md:w-4/12 text-center justify-center ">
                <a
                  href="https://www.facebook.com/elegantcarve2022"
                  target="_blank"
                >
                  <i className="text-white fab fa-facebook text-2xl leading-lg px-4" />
                </a>
                <a
                  href="https://twitter.com/elegantcarve?lang=en"
                  target="_blank"
                >
                  <i className="text-white fab fa-twitter text-2xl leading-lg px-4" />
                </a>
                <a
                  href=" https://instagram.com/elegantcarvetechnologies?igshid=YmMyMTA2M2Y="
                  target="_blank"
                >
                  <i className="text-white fab fa-instagram-square text-2xl leading-lg px-4" />
                </a>
                <a
                  href="https://in.linkedin.com/company/elegantcarve"
                  target="_blank"
                >
                  <i className="text-white fab fa-linkedin text-2xl leading-lg px-4" />
                </a>
                <a
                  href=" https://in.pinterest.com/elegantcarve/"
                  target="_blank"
                >
                  <i className="text-white fab fa-pinterest text-2xl leading-lg px-4" />
                </a>
              </div>
              <div className="lg:w-1/12 md:w-1/12"></div>
              <div className="lg:w-7/12 md:w-7/12 center">
                <h1 className="head1 text-white text-base pt-2 pl-2">
                  Sitemap
                  <span className="px-4">Terms</span>
                  <span className="px-2">Privacy Notice</span>
                  <span className="px-2">Cookie Notice</span>
                </h1>
              </div>
            </div>
          </div>
        </section>
      </footer>
    </>
  );
}

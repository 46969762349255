import React, {useEffect, useState }from "react";
import IndexNavbar2 from "components/Navbars/IndexNavbar2";
import Footer from "components/Footers/Footer";
import servicebg from "assets/img/servicebg.jpg";

const Services = () => {
  //conent coming from left to right start
  useEffect(() => {
    const leftElements = document.querySelectorAll(".left-content");
    const rightElements = document.querySelectorAll(".right-content");

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add(
              entry.target.classList.contains("left-content")
                ? "slide-in-left"
                : "slide-in-right"
            );
            entry.target.classList.remove("opacity-0");
            observer.unobserve(entry.target);
          }
        });
      },
      { threshold: 0.1 }
    );

    leftElements.forEach((el) => {
      observer.observe(el);
    });
    rightElements.forEach((el) => {
      observer.observe(el);
    });

    return () => {
      leftElements.forEach((el) => observer.unobserve(el));
      rightElements.forEach((el) => observer.unobserve(el));
    };
  }, []);
  //conent coming from left to right end 
  return (
    <section className="overflow-hidden">
      <IndexNavbar2 />
      <section
        style={{
          backgroundImage: `url(${servicebg})`,
          backgroundSize: "cover",
          position: "relative",
          backgroundAttachment: "fixed",
        }}
        className="xl:h-730 lg:h-600 md:h-500 h-350"
      >
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0, 0, 0, 0.3)",
            zIndex: 1,
          }}
        />
        <div style={{ position: "relative", zIndex: 2 }}>
          <div className="container xl:px-20 xl:pt-76 lg:pt-52 md:pt-40 pt-28 mx-auto center-items">
            <h1 className="xl:w-4/12 xl:pt-8 xl:text-4xl lg:text-4xl md:text-3xl text-2xl text-base text-white banner-font">
              Our Services
            </h1>
            <p className="right-content opacity-0 xl:w-4/12 lg:w-4/12 md:w-6/12 w-8/12 xl:pt-4 pt-3 xl:text-base1 text-base text-white banner-font">
              As an architect you design for the present, with an awareness of
              the past, for a future which is essentially unknown.
            </p>
            <button className="left-content opacity-0 text-white banner-font border-white px-6 py-2 xl:mt-8 mt-4 onhover">
              Learn More ⌵
            </button>
          </div>
        </div>
      </section>

      <section style={{ backgroundColor: "#262724" }}>
        <div className="container mx-auto overflow-hidden px-4 xl:py-16 xl:px-20 lg:py-8 py-6">
          <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-4">
            <div className="left-content opacity-0 hover-section relative xl:p-8 p-4">
              <div className="text-container relative z-2">
                <h1 className="banner-font number xl:text-3xl">01</h1>
                <h3 className="font-semibold uppercase banner-font xl:text-xl text-white">
                  ARCHITECTURE
                </h3>
                <p className="xl:pt-4 xl:text-base1 text-base text-white banner-font">
                  Architecture bibendum eros eget lacus the vulputate, sit amet
                  vehicula nibh placerat in lectus vitae justo pulvinar cursus.
                </p>
              </div>
              <div className="overlay-image" />
            </div>

            <div className="hover-section relative xl:p-8 p-4">
              <div className="text-container relative z-2">
                <h1 className="banner-font number xl:text-3xl">02</h1>
                <h3 className="font-semibold uppercase banner-font xl:text-xl text-white">
                  interior design
                </h3>
                <p className="xl:pt-4 xl:text-base1 text-base text-white banner-font">
                  Interior design eros eget lacus the vulputate, sit amet
                  vehicula nibh placerat in lectus vitae the justo pulvinar of
                  cursus.
                </p>
              </div>
              <div className="overlay-image1" />
            </div>

            <div className="right-content opacity-0 hover-section relative xl:p-8 p-4">
              <div className="text-container relative z-2">
                <h1 className="banner-font number xl:text-3xl">03</h1>
                <h3 className="font-semibold uppercase banner-font xl:text-xl text-white">
                  urban design
                </h3>
                <p className="xl:pt-4 xl:text-base1 text-base text-white banner-font">
                  Urban design eros eget lacus the vulputate, sit amet vehicula
                  nibh placerat in lectus vitae the justo pulvinar of cursus.
                </p>
              </div>
              <div className="overlay-image2" />
            </div>

            <div className="left-content opacity-0 hover-section relative xl:p-8 p-4">
              <div className="text-container relative z-2">
                <h1 className="banner-font number xl:text-3xl">04</h1>
                <h3 className="font-semibold uppercase banner-font xl:text-xl text-white">
                  planning
                </h3>
                <p className="xl:pt-4 xl:text-base1 text-base text-white banner-font">
                  Architecture bibendum eros eget lacus the vulputate, sit amet
                  vehicula nibh placerat in lectus vitae justo pulvinar cursus.
                </p>
              </div>
              <div className="overlay-image2" />
            </div>

            <div className="hover-section relative xl:p-8 p-4">
              <div className="text-container relative z-2">
                <h1 className="banner-font number xl:text-3xl">05</h1>
                <h3 className="font-semibold uppercase banner-font xl:text-xl text-white">
                  3d modelling
                </h3>
                <p className="xl:pt-4 xl:text-base1 text-base text-white banner-font">
                  Interior design eros eget lacus the vulputate, sit amet
                  vehicula nibh placerat in lectus vitae the justo pulvinar of
                  cursus.
                </p>
              </div>
              <div className="overlay-image1" />
            </div>

            <div className="right-content opacity-0 hover-section relative xl:p-8 p-4">
              <div className="text-container relative z-2">
                <h1 className="banner-font number xl:text-3xl">06</h1>
                <h3 className="font-semibold uppercase banner-font xl:text-xl text-white">
                  decor plans
                </h3>
                <p className="xl:pt-4 xl:text-base1 text-base text-white banner-font">
                  Urban design eros eget lacus the vulputate, sit amet vehicula
                  nibh placerat in lectus vitae the justo pulvinar of cursus.
                </p>
              </div>
              <div className="overlay-image" />
            </div>
          </div>
        </div>
      </section>

      <section
        style={{
          backgroundColor: "#f5f5f5",
          backgroundSize: "cover",
        }}
        className=""
      >
        <div className="container mx-auto overflow-hidden md:px-4 px-0 xl:py-16 xl:px-12 lg:py-16 py-6">
          <div className="grid md:grid-cols-2 grid-cols-1 xl:px-16 lg:px-20 md:px-12 px-4 items-center">
            <div className="left-content opacity-0 overlayyy border-l xl:pl-8 xl:pb-8 lg:pb-8 md:pb-4 md:pl-4 lg:pl-8 z-2 md:p-0 xl:p-0 p-4">
              <div className="text-left">
                <h1 className="xl:pt-8 lg:pt-8 md:pt-4 xl:text-sm uppercase text-base text-white banner-font">
                  HAVE A PROJECT IN MIND?
                </h1>
                <h1 className="xl:pt-4 pt-4 lg:text-4xl md:text-2xl text-3xl xl:text-6xl text-base text-white banner-font">
                  Building A Legacy, One Project At A Time
                </h1>
                <p className="xl:pt-4 xl:text-base1 text-base text-white banner-font">
                  Stay connected with our team and never miss a design update
                </p>
                <button className="xl:mt-8 mt-4 text-white uppercase text-sm font-semibold banner-font border-white px-6 py-2 onhover">
                  let's start talk
                </button>
              </div>
            </div>
            <div className="right-content opacity-0 xl:-ml-100 lg:-ml-28 md:-ml-20 md:mt-0 mt-4">
              <img
                src="https://i.ibb.co/XVRcx6W/cta-two-bg.jpg"
                className="w-full xl:h-500 lg:h-355 md:h-300"
              />
            </div>
          </div>
        </div>
      </section>
      <Footer fixed />
    </section>
  );
};

export default Services;

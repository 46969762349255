import React,{useEffect} from "react";
import IndexNavbar2 from "components/Navbars/IndexNavbar2";
import Footer from "components/Footers/Footer";

export default function Portfolio() {
  //conent coming from left to right start
  useEffect(() => {
    const leftElements = document.querySelectorAll(".left-content");
    const rightElements = document.querySelectorAll(".right-content");

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add(
              entry.target.classList.contains("left-content")
                ? "slide-in-left"
                : "slide-in-right"
            );
            entry.target.classList.remove("opacity-0");
            observer.unobserve(entry.target);
          }
        });
      },
      { threshold: 0.1 }
    );

    leftElements.forEach((el) => {
      observer.observe(el);
    });
    rightElements.forEach((el) => {
      observer.observe(el);
    });

    return () => {
      leftElements.forEach((el) => observer.unobserve(el));
      rightElements.forEach((el) => observer.unobserve(el));
    };
  }, []);
  //conent coming from left to right end
  return (
    <>
      <IndexNavbar2 fixed />
      <section
        style={{
          backgroundColor: "#fff",
          backgroundSize: "cover",
        }}
        className="xl:h-550 lg:h-450 md:h-400 h-420"
      >
        <div className="container xl:px-20 px-4 xl:pt-52 lg:pt-32 md:pt-32 pt-28 mx-auto overflow-hidden">
          <div className="left-content opacity-0 grid lg:grid-cols-2 md:grid-cols-1 ">
            <div>
              <p className="xl:text-sm md:text-xs text-xs banner-font uppercase text-black font-medium text-left">
                Our Portfolio  
              </p>
              <p className="lg:text-3xl md:text-2xl text-2xl lg:w-12/12 md:w-6/12 xl:pt-4 pt-4 xl:pr-20 text-lg banner-font text-black font-medium text-left">
                Our projects are examples of attitudes rather than designs.
              </p>
              <p className="xl:text-base1 xl:pt-2 pt-2 lg:w-12/12 md:w-9/12 banner-font text-greyy font-medium text-left">
                Craft beer elit seitan exercitation, photo booth et 8-bit kale
                chips proident chillwave deep v laborum. Aliquip veniam
                delectus, Marfa eiusmod Pinterest in do umami readymade swag.
                Selfies iPhone Kickstarter, drinking vinegar jean vinegar
                stumptown yr pop-up artisan.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section
        style={{
          backgroundColor: "#f5f5f5",
          width: "100%",
          backgroundSize: "cover",
        }}
      >
        <div className="container xl:px-20 mx-auto overflow-hidden px-4 xl:py-16 lg:py-12 py-6">
          <div className="grid md:grid-cols-2 grid-cols-1 xl:gap-12 lg:gap-8 md:gap-8 gap-4 md:pt-0 pt-8">
            <div className="">
              <div className="left-content opacity-0 cursor-pointer center-items">
                <img
                  src="https://i.ibb.co/WxNszPb/r-architecture-0t-KCSy-LXq-QM-unsplash-768x512.jpg"
                  alt="Xiami Office Building"
                />
                <div className="hover-border bg-white xl:w-9/12 xl:p-8 xl:-mt-12 lg:-mt-12 md:-mt-12 -mt-12">
                  <p className="lg:text-2xl text-lg banner-font text-black font-medium text-left">
                    Xiami Office Building
                  </p>
                  <p className="text-sm banner-font text-greyy font-medium text-left">
                    July 2018
                  </p>
                </div>
              </div>

              <div className="left-content opacity-0 center-items xl:mt-12 lg:mt-12 md:mt-10 mt-4 cursor-pointer">
                <img alt="your browser does not support this iamge" src="https://i.ibb.co/1RFQ6Dg/r-architecture-T6d96-Qrb5-MY-unsplash-768x522.jpg" />
                <div className="hover-border bg-white xl:w-9/12 xl:p-8 z-2 xl:-mt-12 lg:-mt-12 md:-mt-12 -mt-12">
                  <p className="lg:text-2xl text-lg banner-font text-black font-medium text-left">
                    Wooden House
                  </p>
                  <p className="text-sm banner-font text-greyy font-medium text-left">
                    July 2018
                  </p>
                </div>
              </div>

              <div className="left-content opacity-0 center-items xl:mt-12 lg:mt-12 md:mt-10 mt-4 cursor-pointer">
                <img src="https://i.ibb.co/F7V0W8Z/jorge-percival-QW32kg-Fye3c-unsplash-768x1152.jpg" />
                <div className="hover-border bg-white xl:w-9/12 xl:p-8 z-2 xl:-mt-12 lg:-mt-12 md:-mt-12 -mt-12">
                  <p className="lg:text-2xl text-lg banner-font text-black font-medium text-left">
                    Rustic Steel House
                  </p>
                  <p className="text-sm banner-font text-greyy font-medium text-left">
                    July 2018
                  </p>
                </div>
              </div>

              <div className="left-content opacity-0 center-items xl:mt-12 lg:mt-12 md:mt-10 mt-4 cursor-pointer">
                <img src="https://i.ibb.co/t8PkC9g/high-angle-view-of-a-modern-spiral-staircase-in-an-2023-02-04-02-25-21-utc-768x768.jpg" />
                <div className="hover-border bg-white xl:w-9/12 xl:p-8 z-50 xl:-mt-12 lg:-mt-12 md:-mt-12 -mt-12">
                  <p className="lg:text-2xl text-lg banner-font text-black font-medium text-left">
                    Modern York Top
                  </p>
                  <p className="text-sm banner-font text-greyy font-medium text-left">
                    July 2018
                  </p>
                </div>
              </div>
            </div>

            {/* Second grid */}

            <div>
              <div className="right-content opacity-0 cursor-pointer center-items">
                <img src="https://i.ibb.co/qRC5vqD/pixasquare-622732-unsplash-1024x1024-768x768.jpg" />
                <div className="hover-border bg-white xl:w-9/12 xl:p-8 z-50 xl:-mt-12 lg:-mt-12 md:-mt-12 -mt-12">
                  <p className="lg:text-2xl text-lg banner-font text-black font-medium text-left">
                    Modern Mnimalist House
                  </p>
                  <p className="text-sm banner-font text-greyy font-medium text-left">
                    July 2018
                  </p>
                </div>
              </div>

              <div className="right-content opacity-0 center-items xl:mt-12 lg:mt-12 md:mt-10 mt-4 cursor-pointer">
                <img src="https://i.ibb.co/vvcZZRy/paul-menz-j-FV7-He-Sl-h0-unsplash-768x768.jpg" />
                <div className="hover-border bg-white xl:w-9/12 xl:p-8 z-50 xl:-mt-12 lg:-mt-12 md:-mt-12 -mt-12">
                  <p className="lg:text-2xl text-lg banner-font text-black font-medium text-left">
                    Prototype Building
                  </p>
                  <p className="text-sm banner-font text-greyy font-medium text-left">
                    July 2018
                  </p>
                </div>
              </div>

              <div className="right-content opacity-0 center-items xl:mt-12 lg:mt-12 md:mt-10 mt-4 cursor-pointer">
                <img src="https://i.ibb.co/G2g4zXv/anthony-mcgee-EQHjzz-Bc-Kl-E-unsplash-768x626.jpg" />
                <div className="hover-border bg-white xl:w-9/12 xl:p-8 z-50 xl:-mt-12 lg:-mt-12 md:-mt-12 -mt-12">
                  <p className="lg:text-2xl text-lg banner-font text-black font-medium text-left">
                    Riverside House
                  </p>
                  <p className="text-sm banner-font text-greyy font-medium text-left">
                    July 2018
                  </p>
                </div>
              </div>

              <div className="right-content opacity-0 center-items xl:mt-12 lg:mt-12 md:mt-10 mt-4 cursor-pointer">
                <img src="https://i.ibb.co/PT8NmZc/creative-modern-architecture-2022-11-09-19-54-48-utc-768x512.jpg" />
                <div className="hover-border bg-white xl:w-9/12 xl:p-8 z-50 xl:-mt-12 lg:-mt-12 md:-mt-12 -mt-12">
                  <p className="lg:text-2xl text-lg banner-font text-black font-medium text-left">
                    Modern Block House
                  </p>
                  <p className="text-sm banner-font text-greyy font-medium text-left">
                    July 2018
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer fixed />
    </>
  );
}

import React, { useState } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/styles/tailwind.css";
import "assets/styles/style.css";

import Index from "views/Index.js";
import Contactus from "about/aboutpage/Contactus";
import FooterSmallNew from "components/Footers/FooterSmallNew";
import Portfolio from "components/Homepage/Portfolio";
import Aboutus from "about/aboutpage/Aboutus";
import Services from "components/Homepage/Services";
import ScrollToTop from "components/ScrollToTop";

ReactDOM.render(
  <BrowserRouter>
  <ScrollToTop />
    <Switch>
      <Route path="/elegantcarve-homepage" exact component={Index} />

      {/* Contact us */}
      <Route path="/contact-us" exact component={Contactus} />
      <Route path="/portfolio" exact component={Portfolio} />
      <Route path="/about-us" exact component={Aboutus} />
      <Route path="/services" exact component={Services} />

      {/* Pages */}
      <Route path="/" exact component={Index} />
      <Route path="/footersmall" exact component={FooterSmallNew} />

      {/* add redirect for first page */}
      <Redirect from="*" to="/" />
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);

import React from "react";
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import Homepage from "components/Homepage/Homepage";

export default function Index() {
  return (
    <section className="relative overflow-hidden">
      <IndexNavbar/>
      <Homepage/>
    </section>
  );
}

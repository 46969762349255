import React, { useEffect, useRef, useState } from "react";
import IndexNavbar from "components/Navbars/IndexNavbar";
import architecture from "assets/img/architecture.jpg";
import yuvame from "assets/img/yuvame.jpg";
import Footer from "components/Footers/Footer";

const Homepage = () => {
  const texts = ["Buildings", "Homes", "Gardens"];
  const [currentText, setCurrentText] = useState("");
  const [isDeleting, setIsDeleting] = useState(false);
  const [loopNum, setLoopNum] = useState(0);
  const [typingSpeed, setTypingSpeed] = useState(150);

  useEffect(() => {
    const handleTyping = () => {
      const i = loopNum % texts.length;
      const fullText = texts[i];

      setCurrentText(
        isDeleting
          ? fullText.substring(0, currentText.length - 1)
          : fullText.substring(0, currentText.length + 1)
      );

      setTypingSpeed(isDeleting ? 30 : 150);

      if (!isDeleting && currentText === fullText) {
        setTimeout(() => setIsDeleting(true), 500);
      } else if (isDeleting && currentText === "") {
        setIsDeleting(false);
        setLoopNum(loopNum + 1);
      }
    };

    const timer = setTimeout(() => handleTyping(), typingSpeed);

    return () => clearTimeout(timer);
  }, [currentText, isDeleting, loopNum, texts, typingSpeed]);

  useEffect(() => {
    const leftElements = document.querySelectorAll(".left-content");
    const rightElements = document.querySelectorAll(".right-content");

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add(
              entry.target.classList.contains("left-content")
                ? "slide-in-left"
                : "slide-in-right"
            );
            entry.target.classList.remove("opacity-0");
            observer.unobserve(entry.target);
          }
        });
      },
      { threshold: 0.1 }
    );

    leftElements.forEach((el) => {
      observer.observe(el);
    });
    rightElements.forEach((el) => {
      observer.observe(el);
    });

    return () => {
      leftElements.forEach((el) => observer.unobserve(el));
      rightElements.forEach((el) => observer.unobserve(el));
    };
  }, []);

  return (
    <section className="overflow-hidden">
      <IndexNavbar />
      <section
        style={{
          backgroundImage: `url(${architecture})`,
          backgroundSize: "cover",
        }}
        className="xl:h-730 lg:h-600 md:h-500 h-420"
      >
        <div className="container xl:px-20 px-4 xl:pt-64 lg:pt-44 md:pt-36 pt-28 mx-auto flex flex-wrap">
          <div className="grid md:grid-cols-2 grid-cols-1 md:mr-0 mr-20">
            <h1 className="text-white xl:text-5xl lg:text-4xl md:text-3xl text-2xl banner-font typewriter right-content opacity-0">
              We shape our <span>{currentText}│</span> thereafter they shape us
            </h1>
          </div>
        </div>
      </section>

      <section className="bg-white">
        <div className="container mx-auto overflow-hidden px-4 xl:py-16 xl:px-12 lg:py-8 py-6">
          <p className="text-sm banner-font text-greyy font-medium text-left">
            EXPLORE
          </p>
          <p className="pt-1 lg:text-2xl text-lg banner-font text-black font-medium text-left">
            Portfolios
          </p>
          <div className="md:flex flex-none xl:w-12/12 gap-12 mt-8">
            <div className="xl:w-8/12 lg:w-6/12 md:w-6/12 left-content opacity-0">
              <img
                src="https://i.ibb.co/4t0jSFC/lycs-lycs-744227-unsplash-1024x721.jpg"
                className="image-hover md:mt-0 mt-4"
              />
            </div>
            <div className="xl:w-4/12 lg:w-6/12 md:w-6/12 xl:mt-24 right-content opacity-0">
              <p className="lg:text-2xl text-lg banner-font text-black font-medium text-left md:mt-0 mt-4">
                Xiami Office Building
              </p>
              <p className="text-sm banner-font text-greyy font-medium text-left">
                July 2018
              </p>
              <p className="xl:pt-8 text-greyy xl:text-base1 text-base banner-font">
                Exercitation photo booth stumptown tote bag Banksy, elit small
                batch freegan sed. Craft beer elit seitan exercitation, photo
                booth et 8-bit kale chips proident chillwave deep v laborum.
                Aliquip veniam delectus.
              </p>
              <p className="text-3xl md:pt-8 pt-4 cursor-pointer banner-font hoverminus text-greyy font-medium text-left">
                →
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-white">
        <div className="container mx-auto overflow-hidden px-4 xl:py-16 xl:px-12 lg:py-8 py-6">
          <div className="md:flex flex-none xl:w-12/12 gap-12 mt-8">
            <div className="xl:w-4/12 lg:w-6/12 md:w-6/12 xl:mt-24 left-content opacity-0">
              <p className="lg:text-xl text-lg banner-font text-black font-medium text-right md:-mt-0 -mt-4">
                Elegant Brick House
              </p>
              <p className="text-sm banner-font text-greyy font-medium text-right">
                July 2018
              </p>
              <p className="xl:pt-8 text-greyy text-right xl:text-base1 text-base banner-font">
                Marfa eiusmod Pinterest in do umami readymade swag. Selfies
                iPhone Kickstarter, drinking vinegar jean vinegar stumptown yr
                pop-up artisan.
              </p>
              <p className="text-3xl md:pt-8 pt-4 cursor-pointer banner-font hoverminus text-greyy font-medium text-right">
                →
              </p>
            </div>
            <div className="xl:w-8/12 lg:w-6/12 md:w-6/12 right-content opacity-0">
              <img
                src="https://i.ibb.co/jv6cCHS/harprit-bola-239056-unsplash-1024x676.jpg"
                className="image-hover"
              />
            </div>
          </div>
        </div>
      </section>

      <section className="bg-white">
        <div className="container mx-auto overflow-hidden px-4 xl:py-16 xl:px-12 lg:py-8 py-6">
          <div className="md:flex flex-none xl:w-12/12 gap-12 mt-8">
            <div className="md:block opacity-0 xl:w-8/12 lg:w-6/12 md:w-6/12 left-content opacity-0">
              <img
                src="https://i.ibb.co/SQtT7G8/paul-menz-j-FV7-He-Sl-h0-unsplash.jpg"
                className="image-hover"
              />
            </div>
            <div className="xl:w-4/12 lg:w-6/12 md:w-6/12 xl:mt-24 right-content opacity-0">
              <p className="lg:text-2xl text-lg banner-font text-black font-medium text-left md:mt-0 mt-4">
                The Bowery House
              </p>
              <p className="text-sm banner-font text-greyy font-medium text-left">
                July 2018
              </p>
              <p className="xl:pt-8 text-greyy xl:text-base1 text-base banner-font">
                Narwhal pork belly edison bulb prism craft beer post-ironic
                bicycle rights wolf, put a bird on it helvetica fixie man braid.
                Meh polaroid.
              </p>
              <p className="text-3xl md:pt-8 pt-4 cursor-pointer banner-font hoverminus text-greyy font-medium text-left">
                →
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-white">
        <div className="container mx-auto overflow-hidden px-4 xl:py-16 xl:px-12 lg:py-8 py-6">
          <div className="md:flex flex-none xl:w-12/12 gap-12 mt-8">
            <div className="xl:w-4/12 lg:w-6/12 md:w-6/12 xl:mt-24 left-content opacity-0">
              <p className="lg:text-xl text-lg banner-font text-black font-medium text-right md:-mt-0 -mt-4">
                Elegant Brick House
              </p>
              <p className="text-sm banner-font text-greyy font-medium text-right">
                July 2018
              </p>
              <p className="xl:pt-8 text-greyy text-right xl:text-base1 text-base banner-font">
                Marfa eiusmod Pinterest in do umami readymade swag. Selfies
                iPhone Kickstarter, drinking vinegar jean vinegar stumptown yr
                pop-up artisan.
              </p>
              <p className="text-3xl md:pt-8 pt-4 cursor-pointer banner-font hoverminus text-greyy font-medium text-right">
                →
              </p>
            </div>
            <div className="xl:w-8/12 lg:w-6/12 md:w-6/12 right-content opacity-0">
              <img
                src="https://i.ibb.co/jv6cCHS/harprit-bola-239056-unsplash-1024x676.jpg"
                className="image-hover"
              />
            </div>
          </div>
        </div>
      </section>

      <section className="bg-white">
        <div className="container mx-auto overflow-hidden px-4 xl:py-16 xl:px-12 lg:py-8 py-6">
          <div className="md:flex flex-none xl:w-12/12 gap-12 mt-8">
            <div className="xl:w-8/12 lg:w-6/12 md:w-6/12 left-content opacity-0">
              <img
                src="https://i.ibb.co/4t0jSFC/lycs-lycs-744227-unsplash-1024x721.jpg"
                className="image-hover md:mt-0 mt-4"
              />
            </div>
            <div className="xl:w-4/12 lg:w-6/12 md:w-6/12 xl:mt-24 right-content opacity-0">
              <p className="lg:text-2xl text-lg banner-font text-black font-medium text-left md:mt-0 mt-4">
                Xiami Office Building
              </p>
              <p className="text-sm banner-font text-greyy font-medium text-left">
                July 2018
              </p>
              <p className="xl:pt-8 text-greyy xl:text-base1 text-base banner-font">
                Exercitation photo booth stumptown tote bag Banksy, elit small
                batch freegan sed. Craft beer elit seitan exercitation, photo
                booth et 8-bit kale chips proident chillwave deep v laborum.
                Aliquip veniam delectus.
              </p>
              <p className="text-3xl md:pt-8 pt-4 cursor-pointer banner-font hoverminus text-greyy font-medium text-left">
                →
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* <section className="md:py-12 py-8 bg-footerbg">
        <div className="container mx-auto">
          <div className="md:flex md:items-center">
            <div className="lg:flex items-center lg:w-1/2 w-full">
              <img
                src={yuvame}
                className="lg:w-1/4 w-2/4 lg:mx-0 mx-auto rounded-full"
              />
              <div className="lg:pl-12 w-full lg:pt-0 pt-6">
                <p className="banner-font lg:text-3xl text-xl text-center md:text-left text-white">
                  Yuva Architects are very professional and have a lot of
                  experience in their work.
                </p>
                <p className="banner-font text-greyy md:mt-3 mt-2 text-base md:text-left text-center">
                  - Jassica Priston
                </p>
              </div>
            </div>
            <div className="lg:w-1/2 md:pt-0 pt-6 w-full text-center md:text-left">
              <p className="banner-font text-lg text-white text-center md:text-left">
                Interested to Find out More? Contact Us and Start your project
                today.
              </p>
              <button className="bg-orange-500 banner-font md:mt-5 mt-6 px-5 py-3 text-white font-medium uppercase text-sm md:rounded md:mx-0 mx-auto">
                GET STARTED
              </button>
            </div>
          </div>
        </div>
      </section> */}

      <section
        style={{
          backgroundImage: `url(${yuvame})`,
          backgroundSize: "cover",
        }}
        className="xl:h-500 lg:h-350 md:h-250 h-300 "
      >
        <div className="container xl:px-20 xl:pt-64 lg:pt-32 md:pt-20 pt-28 mx-auto overflow-hidden left-content opacity-0">
          <div className="center-items ">
            <h1 className="text-black xl:w-4/12 lg:w-4/12 md:w-5/12 w-8/12 xl:text-2xl text-lg banner-font">
              “Building your own home is about desire, fantasy. But it’s
              achievable anyone can do it.”
            </h1>
            <p className="pt-4 text-sm banner-font text-greyy font-medium text-left">
              KEVIN MCCLOUD, DESIGNER
            </p>
          </div>
        </div>
      </section>

      <section
        style={{
          backgroundColor: "#ffffff",
          backgroundSize: "cover",
        }}
      >
        <div className="right-content opacity-0 container xl:px-32 lg:px-20 md:px-4 px-4 md:py-12 lg:py-16 xl:py-16 py-8 mx-auto overflow-hidden">
          <div className="md:flex flex-none xl:w-12/12 xl:p-12 lg:p-8 p-4 border-bb">
            <div className="xl:w-3/12 lg:w-5/12 md:w-4/12">
              <h1 className="lg:text-2xl md:text-xl text-lg banner-font text-black font-medium text-left">
                Sign up for our Newsletter
              </h1>
              <p className="xl:text-sm lg:text-sm md:text-sm pt-4 text-lg banner-font text-grey font-medium text-left">
                Stay up to date on exciting projects and upcoming events from
                Architecturer Studio.
              </p>
            </div>
            <div className="xl:w-4/12 lg:w-4/12 md:w-4/12 border-b xl:ml-8">
              <input
                type="text"
                placeholder="Enter Email"
                className="border-white xl:mt-20 lg:mt-10 md:mt-16 mt-8 w-full"
              />
            </div>
            <div className="xl:w-4/12 lg:w-3/12 md:w-4/12">
              <button className="xl:mt-20 xl:ml-32 lg:ml-12 md:ml-8 md:mt-20 lg:mt-10 mt-8 xl:px-10 px-6 py-2 bg-black text-white text-sm banner-font font-medium">
                Submit
              </button>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </section>
  );
};

export default Homepage;

import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import eclogohead from "assets/img/eclogohead.png";
import WEBDESIGN from "assets/img/imgs/WEBDESIGN.png";
import WEBDEVELOPMENT from "assets/img/imgs/WEBDEVELOPMENT.png";
import MARKETING from "assets/img/imgs/MARKETING.png";
import APPDEVELOPMENT from "assets/img/imgs/APPDEVELOPMENT.png";
import ECOMMERCE from "assets/img/imgs/ECOMMERCE.png";
import TESTIMONIALS from "assets/img/imgs/TESTIMONIALS.png";
import ABOUTUS from "assets/img/imgs/ABOUTUS.png";
import TEAMS from "assets/img/imgs/TEAMS.png";
import PORTFOLIO from "assets/img/imgs/PORTFOLIO.png";
import NavMobile from "./NavMobile";

const IndexNavbar2 = () => {
  const [scrolled, setScrolled] = useState(false);
  const [showServicesDropdown, setShowServicesDropdown] = useState(false);
  const [showAboutDropdown, setShowAboutDropdown] = useState(false);
  const [showOtherDropdown, setShowOtherDropdown] = useState(false);
  const dropdownTimerRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 50;
      if (isScrolled !== scrolled) {
        setScrolled(isScrolled);
      }
    };

    document.addEventListener("scroll", handleScroll);
    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, [scrolled]);

  const handleServicesHover = () => {
    if (dropdownTimerRef.current) {
      clearTimeout(dropdownTimerRef.current);
    }
    setShowServicesDropdown(true);
    setShowAboutDropdown(false); // Hide the About dropdown when hovering over Services
    setShowOtherDropdown(false); // Hide the Other dropdown when hovering over Services
  };

  const handleServicesLeave = () => {
    dropdownTimerRef.current = setTimeout(() => {
      setShowServicesDropdown(false);
    }, 200); // Adjust delay as needed
  };

  const handleAboutHover = () => {
    if (dropdownTimerRef.current) {
      clearTimeout(dropdownTimerRef.current);
    }
    setShowAboutDropdown(true);
    setShowServicesDropdown(false); // Hide the Services dropdown when hovering over About
    setShowOtherDropdown(false); // Hide the Other dropdown when hovering over About
  };

  const handleAboutLeave = () => {
    dropdownTimerRef.current = setTimeout(() => {
      setShowAboutDropdown(false);
    }, 200); // Adjust delay as needed
  };

  const handleOtherHover = () => {
    if (dropdownTimerRef.current) {
      clearTimeout(dropdownTimerRef.current);
    }
    setShowOtherDropdown(true);
    setShowServicesDropdown(false); // Hide the Services dropdown when hovering over Other
    setShowAboutDropdown(false); // Hide the About dropdown when hovering over Other
  };

  const handleOtherLeave = () => {
    dropdownTimerRef.current = setTimeout(() => {
      setShowOtherDropdown(false);
    }, 200); // Adjust delay as needed
  };

  const handleDropdownEnter = () => {
    if (dropdownTimerRef.current) {
      clearTimeout(dropdownTimerRef.current);
    }
  };

  const handleDropdownLeave = () => {
    setShowServicesDropdown(false);
    setShowAboutDropdown(false);
    setShowOtherDropdown(false);
  };

  return (
    <>
      <div
        className={`fixed z-50 w-full xl:block lg:block md:hidden hidden bg-white ${
          scrolled ? "scrolled" : ""
        }`}
      >
        <div
          className={`flex justify-between items-center py-22 px-4 ${
            scrolled ? "text-black" : "text-black border-b"
          }`}
        >
          <div className="container justify-between flex xl:px-nav md:px-0 px-4 py-1 mx-auto mt-1">
          <div>
              <div className="flex">
                <Link to="/homepage">
                  <div>
                    <p className="uppercase banner-font text-sm border px-6 py-2">
                      architecturer
                    </p>
                  </div>
                </Link>
                <div></div>
                <div className="flex mt-2 ml-8 gap-6">
                  <div
                    className="nav-link"
                    onMouseEnter={handleServicesHover}
                    onMouseLeave={handleServicesLeave}
                  >
                    <a href="/">
                      <p className="font-medium text-header banner-font cursor-pointer">
                        Home
                      </p>
                    </a>
                    {/*  {showServicesDropdown && (
                      <div
                        className="menu-dropdown bg-black shadow-xl xl:mt-8 lg:mt-6 xl:mr-max lg:mr-40 xl:-ml-40 animate-slide-up absolute mt-2"
                        onMouseEnter={handleDropdownEnter}
                        onMouseLeave={handleDropdownLeave}
                      >
                        <div className="flex rounded-xl">
                          <div className="w-full xl:w-12/12 lg:w-12/12 md:12/12 pt-4 pb-4">
                            <div className="flex">
                              <div className="pl-4 lg:w-6/12 md:w-6/12 border-r-2">
                                <ul className="">
                                  <li>
                                    <div className="flex flex-wrap">
                                      <div className="lg:w-3/12 md:w-3/12">
                                        <img
                                          className="w-20"
                                          src={WEBDESIGN}
                                          alt="..."
                                        />
                                      </div>
                                      <div className="lg:w-7/12 md:w-7/12 pt-2">
                                        <a href="/service">
                                          <h1 className="font-bold text-sm head1 text-white">
                                            Web Design
                                          </h1>
                                          <p className="text-xs text-white head1">
                                            We deliver high-quality services for
                                            any business.
                                          </p>
                                        </a>
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div className="flex flex-wrap pt-6">
                                      <div className="lg:w-3/12 md:w-3/12">
                                        <img
                                          className="w-20"
                                          src={WEBDEVELOPMENT}
                                          alt="..."
                                        />
                                      </div>
                                      <div className="lg:w-7/12 md:w-7/12 pt-2">
                                        <a href="/service">
                                          <h1 className="font-bold text-sm head1 text-white">
                                            Web Development
                                          </h1>
                                          <p className="text-xs text-white head1">
                                            We develop your website with the
                                            latest trends based on your needs.
                                          </p>
                                        </a>
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div className="flex flex-wrap pt-6">
                                      <div className="lg:w-3/12 md:w-3/12">
                                        <img
                                          className="w-20"
                                          src={MARKETING}
                                          alt="..."
                                        />
                                      </div>
                                      <div className="lg:w-7/12 md:w-7/12 pt-2">
                                        <a href="/service">
                                          <h1 className="font-bold text-sm head1 text-white">
                                            Marketing
                                          </h1>
                                          <p className="text-xs text-white">
                                            Our team understands the need of our
                                            clients to generate better results.
                                          </p>
                                        </a>
                                      </div>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                              <div className="lg:w-6/12 md:w-6/12">
                                <ul className="">
                                  <li>
                                    <div className="flex flex-wrap pl-4">
                                      <div className="lg:w-3/12 md:w-3/12">
                                        <img
                                          className="w-20"
                                          src={APPDEVELOPMENT}
                                          alt="..."
                                        />
                                      </div>
                                      <div className="lg:w-7/12 md:w-7/12 pt-2">
                                        <a href="/service">
                                          <h1 className="font-bold text-sm head1 text-white">
                                            App Development
                                          </h1>
                                          <p className="text-xs text-white">
                                            This is a functional piece of
                                            software driven by dynamic and
                                            interactive features.
                                          </p>
                                        </a>
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div className="flex flex-wrap pt-4 pl-4">
                                      <div className="lg:w-3/12 md:w-3/12">
                                        <img
                                          className="rounded-lg"
                                          src={ECOMMERCE}
                                          alt="..."
                                        />
                                      </div>
                                      <div className="lg:w-7/12 md:w-7/12 pt-2">
                                        <a href="/service">
                                          <h1 className="font-bold text-sm head1 text-white">
                                            E-commerce
                                          </h1>
                                          <p className="text-xs text-white">
                                            We build customized e-commerce
                                            solutions tailored to your business
                                            needs.
                                          </p>
                                        </a>
                                      </div>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )} */}
                  </div>
                  {/* <div
                    className="nav-link"
                    onMouseEnter={handleAboutHover}
                    onMouseLeave={handleAboutLeave}
                  >
                    <p className="text-header banner-font cursor-pointer">Service ⌵</p>
                    {showAboutDropdown && (
                      <div
                        className="menu-dropdown bg-black shadow-xl xl:mt-8 lg:mt-6 xl:mr-max lg:mr-40 xl:-ml-40 animate-slide-up absolute mt-2"
                        onMouseEnter={handleDropdownEnter}
                        onMouseLeave={handleDropdownLeave}
                      >
                        <div className="flex rounded-xl">
                          <div className="w-full xl:w-12/12 lg:w-12/12 md:12/12 xl:pt-4 xl:pb-4">
                            <div className="flex">
                              <div className="w-full lg:w-12/12 md:12/12 xl:pt-4 xl:pb-4">
                                <div className="w-full lg:w-12/12 md:12/12 ">
                                  <div className="flex flex-wrap">
                                    <div className="pl-2 lg:w-6/12 md:w-6/12 border-r-2">
                                      <ul class="">
                                        <li>
                                          <div className="flex flex-wrap">
                                            <div className="lg:w-3/12 md:w-3/12 ">
                                              <img
                                                className="W-20"
                                                src={ABOUTUS}
                                                alt="..."
                                              />
                                            </div>
                                            <div className="lg:w-8/12 md:w-7/12 pt-2 ">
                                              <a href="/about-us">
                                                <h1 className="font-bold text-sm  head1 text-white">
                                                  About Us
                                                </h1>
                                                <p className="text-xs text-normal text-white head1">
                                                  We are most recognized and
                                                  customers favored website
                                                  design company in bangalore.
                                                </p>
                                              </a>
                                            </div>
                                          </div>
                                        </li>
                                        <li>
                                          <div className="flex flex-wrap pt-6">
                                            <div className="lg:w-3/12 md:w-3/12 ">
                                              <img
                                                className="w-20"
                                                src={TEAMS}
                                                alt="..."
                                              />
                                            </div>
                                            <div className="lg:w-8/12 md:w-7/12 pt-2">
                                              <a href="/testimonials">
                                                <h1 className="font-bold text-sm head1 text-white">
                                                  Our Team
                                                </h1>
                                                <p className="text-xs text-normal text-white head1">
                                                  Our team will do each task
                                                  better and faster to complete
                                                  your work.
                                                </p>
                                              </a>
                                            </div>
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                    <div className="lg:w-6/12 md:w-6/12">
                                      <ul class="">
                                        <li>
                                          <div className="flex flex-wrap pl-4">
                                            <div className="lg:w-3/12 md:w-3/12 ">
                                              <img
                                                className="w-20"
                                                src={PORTFOLIO}
                                                alt="..."
                                              />
                                            </div>
                                            <div className="lg:w-8/12 md:w-7/12 pt-2">
                                              <a href="/our-team">
                                                <h1 className="font-bold text-sm  head1 text-white">
                                                  Our Work
                                                </h1>
                                                <p className="text-xs text-white">
                                                  We could help you in different
                                                  areas like website design,
                                                  logo design and brochure
                                                  design
                                                </p>
                                              </a>
                                            </div>
                                          </div>
                                        </li>
                                        <li>
                                          <div className="flex flex-wrap pl-4 pt-8">
                                            <div className="lg:w-3/12 md:w-3/12 ">
                                              <img
                                                className="rounded-lg"
                                                src={TESTIMONIALS}
                                                alt="..."
                                              />
                                            </div>
                                            <div className="lg:w-8/12 md:w-7/12">
                                              <a href="/testimonials">
                                                <h1 className="font-bold text-sm  head1 text-white">
                                                  Testimonials
                                                </h1>
                                                <p className="text-xs text-white">
                                                  Here is an appreciation to our
                                                  work from so many happy
                                                  customers, we really motivated
                                                  by those words from our
                                                  statisfied customers.
                                                </p>
                                              </a>
                                            </div>
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div> */}
                  <Link to="/about-us" className="nav-link">
                    <p className="text-header banner-font">About</p>
                  </Link>
                  <Link to="/portfolio" className="nav-link">
                    <p className="text-header banner-font">Portfolio</p>
                  </Link>
                  {/* <Link to="/services" className="nav-link">
                    <p className="text-header banner-font">Services ⌵</p>
                  </Link> */}
                  <div
                    className="nav-link"
                    onMouseEnter={handleOtherHover}
                    onMouseLeave={handleOtherLeave}
                  >
                    <p className="text-header cursor-pointer banner-font">
                      Services ⌵
                    </p>
                    {showOtherDropdown && (
                      <div
                        className="bg-white shadow-xl xl:mt-8 lg:mt-6 xl:mr-max lg:mr-40 xl:-ml-16 animate-slide-up absolute mt-2"
                        onMouseEnter={handleDropdownEnter}
                        onMouseLeave={handleDropdownLeave}
                      >
                          <div className="w-full xl:w-12/12 lg:w-12/12 md:12/12 xl:pt-3 xl:pr-4 xl:pb-4 xl:pl-6">
                            <ul class="flex">
                              <div className="xl:w-6/12">
                                <li>
                                  <a href="/blog">
                                    <div className="flex">
                                      {/* <div className="lg:w-3/12 md:w-3/12 xl:-ml-4 ">
                                      <img
                                        className="W-20"
                                        src={ABOUTUS}
                                        alt="..."
                                      />
                                    </div> */}
                                      <div className="lg:w-12/12 md:w-12/12 pt-2 ">
                                        <a href="/services">
                                          <h1 className="text-sm banner-font text-black">
                                            ARCHITECTURE
                                          </h1>
                                          <p className="text-xs text-normal text-black banner-font">
                                            A professional who designs buildings
                                            and oversees their construction.
                                          </p>
                                        </a>
                                      </div>
                                    </div>
                                  </a>
                                </li>
                                <li>
                                  <a href="/services">
                                    <div className="flex">
                                      {/* <div className="lg:w-3/12 md:w-3/12 xl:-ml-4 ">
                                      <img
                                        className="w-20"
                                        src={APPDEVELOPMENT}
                                        alt="..."
                                      />
                                    </div> */}
                                      <div className="lg:w-12/12 md:w-12/12 pt-4">
                                        <a href="/about">
                                          <h1 className="text-sm head1 text-black">
                                            INTERIOR DESIGN
                                          </h1>
                                          <p className="text-xs text-normal text-black banner-font">
                                            Our team will do each task better
                                            and faster to complete your work.
                                          </p>
                                        </a>
                                      </div>
                                    </div>
                                  </a>
                                </li>
                                <li>
                                  <a href="/services">
                                    <div className="flex">
                                      {/* <div className="lg:w-3/12 md:w-3/12 xl:-ml-4 ">
                                      <img
                                        className="w-20 mt-2"
                                        src={APPDEVELOPMENT}
                                        alt="..."
                                      />
                                    </div> */}
                                      <div className="lg:w-12/12 md:w-12/12 pt-4">
                                        <a href="/best-plans">
                                          <h1 className="text-sm head1 text-black">
                                            URBAN DESIGN
                                          </h1>
                                          <p className="text-xs text-normal text-black head1">
                                            Exclusively for you, we offer the
                                            finest plans at exceptionally
                                            affordable prices.
                                          </p>
                                        </a>
                                      </div>
                                    </div>
                                  </a>
                                </li>
                              </div>
                              <div className="xl:w-6/12">
                                <li>
                                  <a href="/services">
                                    <div className="flex">
                                      {/* <div className="lg:w-3/12 md:w-3/12 xl:-ml-4 ">
                                      <img
                                        className="w-20 mt-2"
                                        src={APPDEVELOPMENT}
                                        alt="..."
                                      />
                                    </div> */}
                                      <div className="lg:w-12/12 md:w-12/12 pt-2">
                                        <a href="/best-plans">
                                          <h1 className="text-sm font-medium head1 text-black">
                                            PLANNING
                                          </h1>
                                          <p className="text-xs text-normal text-black head1">
                                            Architecturer is a leading firm in
                                            innovative building design and
                                            sustainable architecture solutions
                                          </p>
                                        </a>
                                      </div>
                                    </div>
                                  </a>
                                </li>
                                <li>
                                  <a href="/services">
                                    <div className="flex">
                                      {/* <div className="lg:w-3/12 md:w-3/12 xl:-ml-4 ">
                                      <img
                                        className="w-20 mt-2"
                                        src={APPDEVELOPMENT}
                                        alt="..."
                                      />
                                    </div> */}
                                      <div className="lg:w-12/12 md:w-12/12 pt-4">
                                        <a href="/best-plans">
                                          <h1 className="text-sm head1 text-black">
                                            3D MODELLING
                                          </h1>
                                          <p className="text-xs text-normal text-black head1">
                                            Offer services where clients can see
                                            detailed 3D models of their projects
                                            before construction begins.
                                          </p>
                                        </a>
                                      </div>
                                    </div>
                                  </a>
                                </li>
                                <li>
                                  <a href="/services">
                                    <div className="flex">
                                      {/* <div className="lg:w-3/12 md:w-3/12 xl:-ml-4 ">
                                      <img
                                        className="w-20 mt-2"
                                        src={APPDEVELOPMENT}
                                        alt="..."
                                      />
                                    </div> */}
                                      <div className="lg:w-12/12 md:w-12/12 pt-4">
                                        <a href="/best-plans">
                                          <h1 className="text-sm head1 text-black">
                                            DECOR PLANS
                                          </h1>
                                          <p className="text-xs text-normal text-black head1">
                                            Exclusively for you, we offer the
                                            finest plans at exceptionally
                                            affordable prices.
                                          </p>
                                        </a>
                                      </div>
                                    </div>
                                  </a>
                                </li>
                              </div>
                            </ul>
                        </div>
                      </div>
                    )}
                  </div>{" "}
                  <Link to="/contact-us" className="nav-link">
                    <p className="text-header banner-font">Contact </p>
                  </Link>
                </div>
              </div>
            </div>
            <div className="flex">
              {/* <div className="text-header cursor-pointer mt-1 mr-8">🔒</div> */}
              <div className="mt-2 cursor-pointer mr-4">
                <p className="text-header banner-font">Client Login</p>
              </div>
              <p className="text-header banner-font mt-2">🛒</p>
            </div>
          </div>
        </div>
      </div>

      {/* Mobile Nav */}
      <div className="xl:hidden lg:hidden md:block">
        <NavMobile />
      </div>
    </>
  );
};

export default IndexNavbar2;

import React, { useEffect } from "react";
import IndexNavbar2 from "components/Navbars/IndexNavbar2";
import Footer from "components/Footers/Footer";
import yuvame from "assets/img/yuvame.jpg";

export default function Index() {

  //conent coming from left to right start
  useEffect(() => {
    const leftElements = document.querySelectorAll(".left-content");
    const rightElements = document.querySelectorAll(".right-content");

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add(
              entry.target.classList.contains("left-content")
                ? "slide-in-left"
                : "slide-in-right"
            );
            entry.target.classList.remove("opacity-0");
            observer.unobserve(entry.target);
          }
        });
      },
      { threshold: 0.1 }
    );

    leftElements.forEach((el) => {
      observer.observe(el);
    });
    rightElements.forEach((el) => {
      observer.observe(el);
    });

    return () => {
      leftElements.forEach((el) => observer.unobserve(el));
      rightElements.forEach((el) => observer.unobserve(el));
    };
  }, []);
  //conent coming from left to right end
  return (
    <>
      <IndexNavbar2 fixed />
      <section
        style={{
          backgroundImage: `url(${yuvame})`,
          backgroundSize: "cover",
        }}
        className="xl:h-500 lg:h-350 md:h-300 h-300"
      >
        <div className="container xl:px-20 xl:pt-64 lg:pt-48 md:pt-40 pt-40 mx-auto overflow-hidden">
          <div className="center-items ">
            <h1 className="text-black xl:w-4/12 xl:text-5xl lg:text-4xl md:text-2xl text-2xl banner-font">
             Contact us
            </h1>
          </div>
        </div>
      </section>
      <section
        style={{
          backgroundColor: "#fff",
          width: "100%",
          backgroundSize: "cover",
        }}
      >
        <div className="container xl:px-20 mx-auto overflow-hidden px-4 xl:py-16 lg:py-12 py-6">
          <div className="md:flex flex-none xl:w-12/12 gap-8">
            <div className="left-content opacity-0 xl:w-4/12 lg:w-4/12 md:w-4/12 w-12/12 xl:mr-8">
              <p className="text-sm banner-font text-greyy font-medium text-left">
                EXPLORE
              </p>
              <p className="lg:text-2xl pt-2 text-lg banner-font text-black font-medium text-left">
                Portfolios
              </p>
              <p className="text-base1 xl:w-9/12 banner-font text-grey font-medium text-left">
                We used to build civilizations. Now we build shopping malls.
              </p>
              <img
                className="my-8"
                src="https://i.ibb.co/hRyxDjV/modern-house-exterior-2021-08-27-19-27-31-utc-1536x877.jpg"
              />
              <p className="lg:text-2xl text-lg banner-font text-black font-medium text-left">
                Contemporary Modern House
              </p>
              <p className="text-base1 xl:w-7/12 banner-font text-greyy font-medium text-left">
                May 2018
              </p>
              <img
                className="my-8"
                src="https://i.ibb.co/fnDdjQ9/r-architecture-A8-W70m5jv-k-unsplash-700x466.jpg"
              />
              <p className="lg:text-2xl text-lg banner-font text-black font-medium text-left">
                Elegant Brick House
              </p>
              <p className="text-base1 xl:w-7/12 banner-font text-greyy font-medium text-left">
                May 2018
              </p>
            </div>
            <div className="right-content opacity-0 xl:w-8/12 lg:w-8/12 md:w-8/12 w-12/12">
              <p className="lg:text-2xl md:mt-0 mt-4  xl:text-4xl md:text-xl text-lg banner-font text-black font-medium text-left">
                Contact Us
              </p>
              <div className="md:flex flex-none xl:w-12/12 xl:mt-2">
                <div className="xl:w-3/12">
                  123 East 26th Street, Fifth Floor, New York, NY 10011
                </div>
                <div className="xl:w-3/12 xl:ml-24">(212) 563-7765</div>
                <div className="xl:w-4/12">hello@architecture.com</div>
              </div>
              <div className="xl:p-12 lg:p-8 md:p-8 p-4 border-bb xl:mt-12 lg:mt-8 md:mt-8 mt-4">
                <p className="banner-font text-greyy ">Your Name (required)</p>
                <p className="border-b">
                  <input
                    type="text"
                    className="w-full rounded-md mt-1 border-white "
                  />
                </p>
                <p className="banner-font text-greyy xl:mt-8 mt-4">Your Email (required)</p>
                <p className="border-b">
                  <input
                    type="text"
                    className="w-full rounded-md mt-1 border-white "
                  />
                </p>

                <p className="banner-font text-greyy xl:mt-8 mt-4">Your Message</p>
                <p className="border-b">
                  <input
                    type="text"
                    className="w-full rounded-md mt-1 border-white "
                  />
                </p>
                <button className="xl:mt-4 lg:mt-8 mt-8 bg-black text-white text-sm banner-font xl:px-10 px-6 py-2 font-medium">
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer fixed/>
    </>
  );
}

import React, { useState } from "react";
import EClogo from "assets/img/EClogo.png";
import EClogowhite from "assets/img/EClogowhite.png";
import eclogohead from "assets/img/eclogohead.png";
import contactbg from "assets/img/contactbg.jpg";

export default function FooterSmallNew() {
  const handleButtonClick = () => {
    window.location.href = "tel:+919036323422";
  };

  const handleButtonClickMail = () => {
    window.location.href = "mailto:info@elegantcarve.com";
  };

  return (
    <>
      <footer className="relative bottom-0">
        <section
          style={{
            backgroundImage: `url(${contactbg})`,
            backgroundColor: "#00244f",
            backgroundSize: "cover",
          }}
          className=""
        >
          <div className="container mx-auto overflow-hidden xl:px-12 xl:py-16 xl:w-12/12 flex">
            <div className="xl:w-2/12"></div>
            <div className="xl:w-5/12"></div>
            <div className="xl:w-5/12"></div>

            <div className="xl:p-12 cursor-pointer center-items shadoww-md">
              <h1 className="text-black xl:text-3xl font-semibold banner-font">
                Opening Hours
              </h1>
              <div className="flex justify-between pt-4">
                <p className="text-greyy xl:text-base1 banner-font">Monday</p>
                <p className="text-greyy xl:text-base1 xl:pl-20 banner-font">
                  9.00 - 21.00
                </p>
              </div>
              <div className="flex justify-between pt-4">
                <p className="text-greyy xl:text-base1 banner-font">Tuesday</p>
                <p className="text-greyy xl:text-base1 xl:pl-20 banner-font">
                  10.00 - 22.00
                </p>
              </div>
              <div className="flex justify-between pt-4">
                <p className="text-greyy xl:text-base1 banner-font">Saturday</p>
                <p className="text-greyy xl:text-base1 xl:pl-20 banner-font">
                  9.00 - 20.00
                </p>
              </div>
              <div className="flex justify-between pt-4">
                <p className="text-greyy xl:text-base1 banner-font">Sunday</p>
                <p className="text-greyy xl:text-base1 xl:pl-20 banner-font">
                  8.00 - 22.00
                </p>
              </div>
            </div>
          </div>
          <h1 className="px-2 text-center text-blue banner-font text-xs py-4">
            ©2024 <span className="font-bold">Elegant Carve</span>, All Rights
            Reserved
          </h1>
        </section>
        <FooterSmallNew />
      </footer>
    </>
  );
}

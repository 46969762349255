import React, { useState } from "react";
import { Link } from "react-router-dom";

const NavMobile = () => {
  const [showFirstStep, setShowFirstStep] = useState(false);
  const [showSecondStep, setShowSecondStep] = useState(false);
  const [showServicesStep, setShowServicesStep] = useState(false);

  const renderItem = () => {
    return (
      <div className="banner-font font-semibold text-white mb-20">
        <ul>
          <li className="py-6 border-b">
            <a href="/">HOME</a>
          </li>
          <li className="py-6 border-b">
            <a href="/about-us">ABOUT</a>
          </li>
          <li className="py-6 border-b">
            <a href="/portfolio">PORTFOLIO</a>
          </li>
          <li
            onClick={onClickServices}
            className="flex justify-between py-6 border-b"
          >
            <span onClick={onClickServices}>SERVICES</span>
            <div className="flex justify-between">
              <p className="font-extrabold" onClick={onClickServices}>
                ⌵
              </p>
            </div>
          </li>
          <li className="py-6 border-b">
            <a href="/contact-us">CONTACT</a>
          </li>
        </ul>
      </div>
    );
  };

  const onClickServices = () => {
    setShowFirstStep(false);
    setShowSecondStep(false);
    setShowServicesStep(true);
  };

  const onClickBackButton = () => {
    setShowFirstStep(true);
    setShowSecondStep(false);
    setShowServicesStep(false);
  };

  const onClickClose = () => {
    setShowFirstStep(false);
    setShowSecondStep(false);
    setShowServicesStep(false);
    document.body.style.backgroundColor = "";
  };

  return (
    <nav className="top-0 fixed z-10 w-full flex flex-wrap items-center justify-between navbar-expand-lg lg:hidden md:block block ">
      <div className="bg-transparent overflow-hidden container flex flex-wrap items-center justify-between">
        <div className="w-full bg-white px-4 border relative items-center flex justify-between lg:w-auto lg:static lg:block lg:justify-start">
          <Link to="/homepage">
            <div>
              <p className="uppercase banner-font text-black text-sm border-black px-6 py-2">
                architecturer
              </p>
            </div>
          </Link>
          <div
            className="text-black bg-redd p-4 rounded-md"
            onClick={() => setShowFirstStep(true)}
          >
            ☰
          </div>
        </div>
      </div>
      {showFirstStep && (
        <div
          className="z-50 -mt-0 w-nav md:w-nav animate-slide-left"
          style={{
            backgroundColor:
              showFirstStep || showSecondStep || showServicesStep
                ? ""
                : "bg-black",
          }}
        >
          <div className="overlay-coffee py-6 px-6">
            <div className="flex justify-between">
              <div></div>
              <div onClick={onClickClose} className="text-white font-extrabold">
                ✕
              </div>
            </div>
          </div>
          <ul className="flex overlay-coffee flex-col py-2 md:py-0 px-6 space-y-1">
            {renderItem()}
          </ul>
          <div className="flex justify-between items-center mt-4 mx-4"></div>
        </div>
      )}

      {showServicesStep && (
        <>
          <div className="overflow-y-auto w-full overlay-coffee animate-slide-left -mt-20">
            <div
              onClick={onClickBackButton}
              className="py-8 px-4 flex items-center pt-16"
            >
              <div
                className="ml-2 cursor-pointer top-2"
                aria-hidden="true"
                onClick={onClickBackButton}
              >
                <span className="font-extrabold text-white">〱</span>
              </div>
              <h1 className="banner-font w-full text-white px-4 font-semibold uppercase tracking-wide text-sm">
                Services
              </h1>
            </div>
          </div>
          <div className="overlay-coffee pb-48 px-6 mb-16 animate-slide-left">
            <div className="lg:w-7/12 md:w-7/12 pt-2">
              <a href="/services">
                <h1 className="font-semibold text-sm text-white">
                  ARCHITECTURE
                </h1>
                <p className="text-sm text-white pt-2">
                  A professional who designs buildings and oversees their
                  construction.
                </p>
              </a>
            </div>
            <div className="lg:w-7/12 md:w-7/12 pt-4">
              <a href="/services">
                <h1 className="font-semibold text-sm text-white">
                  INTERIOR DESIGN
                </h1>
                <p className="text-sm text-white pt-2">
                  Our team will do each task better and faster to complete your
                  work.
                </p>
              </a>
            </div>
            <div className="lg:w-7/12 md:w-7/12 pt-4">
              <a href="/services">
                <h1 className="font-semibold text-sm text-white">
                  URBAN DESIGN
                </h1>
                <p className="text-sm text-white pt-2">
                  Exclusively for you, we offer the finest plans at
                  exceptionally affordable prices.
                </p>
              </a>
            </div>
            <div className="lg:w-7/12 md:w-7/12 pt-4">
              <a href="/services">
                <h1 className="font-semibold text-sm text-white">
                  PLANNING
                </h1>
                <p className="text-sm text-white pt-2">
                  Architecturer is a leading firm in innovative building design
                  and sustainable architecture solutions
                </p>
              </a>
            </div>
            <div className="lg:w-7/12 md:w-7/12 pt-4">
              <a href="/services">
                <h1 className="font-semibold text-sm text-white">
                  3D MODELLING
                </h1>
                <p className="text-sm text-white pt-2">
                  Offer services where clients can see detailed 3D models of
                  their projects before construction begins.
                </p>
              </a>
            </div>
            <div className="lg:w-7/12 md:w-7/12 pt-4">
              <a href="/services">
                <h1 className="font-semibold text-sm text-white">
                  DECOR PLANS
                </h1>
                <p className="text-sm text-white pt-2">
                  Exclusively for you, we offer the finest plans at
                  exceptionally affordable prices.
                </p>
              </a>
            </div>
          </div>
        </>
      )}
    </nav>
  );
};

export default NavMobile;
